export enum ErrorIdent {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  USER_ALREADY_EXISTS_SALES_CHANNEL = 'USER_ALREADY_EXISTS_SALES_CHANNEL',
  PASSWORD_HASH_EXPIRED = 'HASH_EXPIRED',
  VAT_ID_NOT_FOUND = 'VAT_ID_NOT_FOUND',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  VERIFICATION_UPLOAD_ERROR = 'VERIFICATION_UPLOAD_ERROR',
  PASSWORDLESS_LOGIN_HASH_EXPIRED = 'PASSWORDLESS_LOGIN_HASH_EXPIRED',
  BAD_GATEWAY = 'BAD_GATEWAY',
  CART_PRODUCT_NOT_AVAILABLE = 'CART_PRODUCT_NOT_AVAILABLE',
  CART_DRUG_ADDRESS_MISMATCH = 'CART_DRUG_ADDRESS_MISMATCH',
  RETURN_GLS_CUSTOMER_HAS_NO_RESPONSIBILITY_UNIT_CODE = 'RETURN_GLS_CUSTOMER_HAS_NO_RESPONSIBILITY_UNIT_CODE',
  RETURN_GLS_NO_RESPONSE = 'RETURN_GLS_NO_RESPONSE',
  PENDING_ORDER_CART_NOT_READY = 'PENDING_ORDER_CART_NOT_READY',
}
