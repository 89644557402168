import {
  mq_breakpointIsDesktop,
  mq_breakpointIsMobile,
  mq_breakpointIsTablet,
  mq_isTouch,
} from '../injectionSymbols';
export async function useAppWideMediaQuerySetup() {
  const breakpoints = useBreakpoints({
    mobile: 768,
    tablet: 960,
    desktop: 1410,
  });

  const isMobile = breakpoints.smaller('mobile');
  const isTablet = breakpoints.between('mobile', 'tablet');
  const isDesktop = breakpoints.greater('tablet');

  if (import.meta.server) {
    // @ts-expect-error TS2540 old code that always worked like this (its probably fine to keep it like this)
    isMobile.value = useRequestHeader('ismobile') === 'true';
  }
  const isTouch = ref(false);

  if (import.meta.client) {
    isTouch.value = window.matchMedia('(hover: none)').matches;
    // @ts-expect-error TS2540 old code that always worked like this (its probably fine to keep it like this)
    isMobile.value = window.matchMedia('(max-width: 768px)').matches;
  }

  provide(mq_breakpointIsMobile, isMobile); // <--- only one working for server side rendering
  provide(mq_breakpointIsTablet, isTablet);
  provide(mq_breakpointIsDesktop, isDesktop);
  provide(mq_isTouch, isTouch);
}

export default useAppWideMediaQuerySetup;
