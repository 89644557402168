import type { InjectionKey } from 'vue';
import type { ComponentVisibilitySettingsType } from './server/transformers/componentVisibilitiesTransformer';
import type { LocationQuery } from 'vue-router';

// Components
export const cmsPageData = Symbol() as InjectionKey<Ref<any>>;
export const styledSectionTextColor = Symbol() as InjectionKey<string | null>;
export const urlQueryInfos = Symbol() as InjectionKey<LocationQuery | null>;
export const filteredListState = Symbol() as InjectionKey<any>;
export const returnLineItemConfig = Symbol() as InjectionKey<any>;
export const orderLineItemConfig = Symbol() as InjectionKey<any>;

// Media Queries
export const SSR_safe_mq_breakpointIsMobile = Symbol() as InjectionKey<
  Ref<boolean>
>;
export const SSR_safe_mq_breakpointIsTablet = Symbol() as InjectionKey<
  Ref<boolean>
>;
export const SSR_safe_mq_breakpointIsDesktop = Symbol() as InjectionKey<
  Ref<boolean>
>;
export const SSR_safe_mq_isTouch = Symbol() as InjectionKey<Ref<boolean>>;

/**
 * @deprecated use css media queries or SSR Safe Breakpoints instead
 */
export const mq_breakpointIsMobile = SSR_safe_mq_breakpointIsMobile;
/**
 * @deprecated use css media queries or SSR Safe Breakpoints instead
 */
export const mq_breakpointIsTablet = SSR_safe_mq_breakpointIsTablet;
/**
 * @deprecated use css media queries or SSR Safe Breakpoints instead
 */
export const mq_breakpointIsDesktop = SSR_safe_mq_breakpointIsDesktop;
/**
 * @deprecated use css media queries or SSR Safe Breakpoints instead
 */
export const mq_isTouch = SSR_safe_mq_isTouch;

export const componentVisibilitySettings =
  Symbol() as InjectionKey<ComponentVisibilitySettingsType>;
