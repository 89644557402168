
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as overviewc1xj66I7ByMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/_/banner/overview.vue?macro=true";
import { default as indexLVUUuMbfDXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/index.vue?macro=true";
import { default as thank_45youmFYd96OGhhMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/thank-you.vue?macro=true";
import { default as index9TNDTf5EP5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/404/index.vue?macro=true";
import { default as indexbinaNHJHopMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/index.vue?macro=true";
import { default as successxncYtBylRPMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/success.vue?macro=true";
import { default as indexOKtZLqRUYIMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/index.vue?macro=true";
import { default as indexm5Ihg0yhWMMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/orders/index.vue?macro=true";
import { default as indexctvctuMfrjMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/profile/index.vue?macro=true";
import { default as index9mwaQWuZXAMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/index.vue?macro=true";
import { default as successPYQpn2U420Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/success.vue?macro=true";
import { default as itemsAO3xt2FTe3Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/items.vue?macro=true";
import { default as reviewksVtklIBnAMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/review.vue?macro=true";
import { default as shippingwt8ShkDLm1Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/shipping.vue?macro=true";
import { default as form65X6XGcv4GMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/complaint/form.vue?macro=true";
import { default as indexEfZoSMtp8lMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/index.vue?macro=true";
import { default as addressBMGYdPyvExMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/address.vue?macro=true";
import { default as basket8dDG7bvDGPMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/basket.vue?macro=true";
import { default as loginmCbiwu2UMKMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/login.vue?macro=true";
import { default as payment_45shipping0zq9O1Sr6MMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/payment-shipping.vue?macro=true";
import { default as reviewzmysQPdBTXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/review.vue?macro=true";
import { default as theme_45testzka5xSUxHAMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/theme-test.vue?macro=true";
import { default as toolwf09nIEBqWMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/tool.vue?macro=true";
import { default as indexzesY8QrvVsMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/index.vue?macro=true";
import { default as successTIJpI9beVhMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/success.vue?macro=true";
import { default as _91slug_93g2K4FeHrg8Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/preview/banner/[slug].vue?macro=true";
import { default as indexm3GxtqUNkMMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/auto-delivery/index.vue?macro=true";
import { default as _91id_93doKGxtHXu9Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/[...slug]/[id].vue?macro=true";
import { default as index716hBx4BlnMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/index.vue?macro=true";
import { default as indexubYHqZB4TgMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/index.vue?macro=true";
import { default as indexIPBiP67HfyMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/order-alarm/index.vue?macro=true";
import { default as indexxOSVtwiqQ5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/ordered-products/index.vue?macro=true";
import { default as indexFS3mBU2aAnMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/viewed-products/index.vue?macro=true";
import { default as indexsU3FSiLt1OMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/index.vue?macro=true";
import { default as successY4rzAFJGECMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/success.vue?macro=true";
import { default as checkoutU3ngDoQeACMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/repairshop/checkout.vue?macro=true";
import { default as index6o7h0b6h2xMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/sales/[productId]/index.vue?macro=true";
import { default as indexNdW7ClDT4LMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/[...slug]/index.vue?macro=true";
import { default as indexDdvrasJ11OMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/manufacturer/[...slug]/index.vue?macro=true";
import { default as _91id_93ESuobqRFiwMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/product/[...slug]/[id].vue?macro=true";
import { default as indexOjpei7QxtcMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/search/[...slug]/index.vue?macro=true";
import { default as overviewAsqzyEZn5ZMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/_/banner/overview.vue?macro=true";
import { default as index1ozAKkrdaeMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/index.vue?macro=true";
import { default as thank_45youHOxaLCpcxPMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/thank-you.vue?macro=true";
import { default as indexxxpelmL8l4Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/404/index.vue?macro=true";
import { default as indexFhwoUCFbGuMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/index.vue?macro=true";
import { default as successvE16D59m1YMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/success.vue?macro=true";
import { default as indexcjAh9gop6eMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/index.vue?macro=true";
import { default as indexPDdiQIauA0Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/orders/index.vue?macro=true";
import { default as indexEieqmBKy3uMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/profile/index.vue?macro=true";
import { default as indexhpY3B3Vnd5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/index.vue?macro=true";
import { default as successIaW4ZhmhYlMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/success.vue?macro=true";
import { default as itemsUyjyPt4NFOMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/items.vue?macro=true";
import { default as reviewheBh9Cr9HNMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/review.vue?macro=true";
import { default as shippingUiXg2kpUm6Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/shipping.vue?macro=true";
import { default as formE3T19pJT0tMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/complaint/form.vue?macro=true";
import { default as indexQLpR1SkXrbMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/index.vue?macro=true";
import { default as addressxXtYUUsyrFMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/address.vue?macro=true";
import { default as basketKCoqvpWcnMMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/basket.vue?macro=true";
import { default as loginmJtOHFQoWhMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/login.vue?macro=true";
import { default as payment_45shipping8N0RqU7HoGMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/payment-shipping.vue?macro=true";
import { default as reviewHHhlExyKELMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/review.vue?macro=true";
import { default as theme_45testEFsRRySBkYMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/theme-test.vue?macro=true";
import { default as tool3zxr8vsFp8Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/tool.vue?macro=true";
import { default as indexi6eOCaDFSXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/index.vue?macro=true";
import { default as successLA4eDiasBaMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/success.vue?macro=true";
import { default as _91slug_93D6qR8t08XvMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/preview/banner/[slug].vue?macro=true";
import { default as index1qujTxPysJMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/auto-delivery/index.vue?macro=true";
import { default as _91id_93yIjhBluM7fMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/[...slug]/[id].vue?macro=true";
import { default as indexvuyMHXIqMGMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/index.vue?macro=true";
import { default as indexzc2pzVJcDQMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/index.vue?macro=true";
import { default as indexBWMR4UtkyDMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/order-alarm/index.vue?macro=true";
import { default as index8wBuIUbBGGMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/ordered-products/index.vue?macro=true";
import { default as index5ZvWiFwnmIMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/viewed-products/index.vue?macro=true";
import { default as index9C1BR7ag0MMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/index.vue?macro=true";
import { default as successD4vHILRR9vMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/success.vue?macro=true";
import { default as checkoutPjEsrXvMAfMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/repairshop/checkout.vue?macro=true";
import { default as indexkFY0baP8tOMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/sales/[productId]/index.vue?macro=true";
import { default as indexaUv5nYhlDXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/[...slug]/index.vue?macro=true";
import { default as index6aixs50fOZMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/manufacturer/[...slug]/index.vue?macro=true";
import { default as _91id_93MNvCWplEX5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/product/[...slug]/[id].vue?macro=true";
import { default as indexr3g6CYwSIkMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/search/[...slug]/index.vue?macro=true";
export default [
  {
    name: "minilu-_-banner-overview",
    path: "/minilu/_/banner/overview",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/_/banner/overview.vue")
  },
  {
    name: "minilu-slug",
    path: "/minilu/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/index.vue")
  },
  {
    name: "minilu-slug-thank-you",
    path: "/minilu/:slug(.*)*/thank-you",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/thank-you.vue")
  },
  {
    name: "minilu-404",
    path: "/minilu/404",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/404/index.vue")
  },
  {
    name: "minilu-account-changePassword",
    path: "/minilu/account/changePassword",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/index.vue")
  },
  {
    name: "minilu-account-changePassword-success",
    path: "/minilu/account/changePassword/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/success.vue")
  },
  {
    name: "minilu-account",
    path: "/minilu/account",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/index.vue")
  },
  {
    name: "minilu-account-orders",
    path: "/minilu/account/orders",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/orders/index.vue")
  },
  {
    name: "minilu-account-profile",
    path: "/minilu/account/profile",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/profile/index.vue")
  },
  {
    name: "minilu-account-resetPassword",
    path: "/minilu/account/resetPassword",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/index.vue")
  },
  {
    name: "minilu-account-resetPassword-success",
    path: "/minilu/account/resetPassword/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/success.vue")
  },
  {
    name: "minilu-account-returns-checkout-items",
    path: "/minilu/account/returns/checkout/items",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/items.vue")
  },
  {
    name: "minilu-account-returns-checkout-review",
    path: "/minilu/account/returns/checkout/review",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/review.vue")
  },
  {
    name: "minilu-account-returns-checkout-shipping",
    path: "/minilu/account/returns/checkout/shipping",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/shipping.vue")
  },
  {
    name: "minilu-account-returns-complaint-form",
    path: "/minilu/account/returns/complaint/form",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/complaint/form.vue")
  },
  {
    name: "minilu-account-returns",
    path: "/minilu/account/returns",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/index.vue")
  },
  {
    name: "minilu-checkout-address",
    path: "/minilu/checkout/address",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/address.vue")
  },
  {
    name: "minilu-checkout-basket",
    path: "/minilu/checkout/basket",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/basket.vue")
  },
  {
    name: "minilu-checkout-login",
    path: "/minilu/checkout/login",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/login.vue")
  },
  {
    name: "minilu-checkout-payment-shipping",
    path: "/minilu/checkout/payment-shipping",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/payment-shipping.vue")
  },
  {
    name: "minilu-checkout-review",
    path: "/minilu/checkout/review",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/review.vue")
  },
  {
    name: "minilu-debug-theme-test",
    path: "/minilu/debug/theme-test",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/theme-test.vue")
  },
  {
    name: "minilu-debug-tool",
    path: "/minilu/debug/tool",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/tool.vue")
  },
  {
    name: "minilu-login",
    path: "/minilu/login",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/index.vue")
  },
  {
    name: "minilu-login-success",
    path: "/minilu/login/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/success.vue")
  },
  {
    name: "minilu-preview-banner-slug",
    path: "/minilu/preview/banner/:slug()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/preview/banner/[slug].vue")
  },
  {
    name: "minilu-productlists-auto-delivery",
    path: "/minilu/productlists/auto-delivery",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/auto-delivery/index.vue")
  },
  {
    name: "minilu-productlists-favorites-slug-id",
    path: "/minilu/productlists/favorites/:slug(.*)*/:id()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/[...slug]/[id].vue")
  },
  {
    name: "minilu-productlists-favorites",
    path: "/minilu/productlists/favorites",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/index.vue")
  },
  {
    name: "minilu-productlists",
    path: "/minilu/productlists",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/index.vue")
  },
  {
    name: "minilu-productlists-order-alarm",
    path: "/minilu/productlists/order-alarm",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/order-alarm/index.vue")
  },
  {
    name: "minilu-productlists-ordered-products",
    path: "/minilu/productlists/ordered-products",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/ordered-products/index.vue")
  },
  {
    name: "minilu-productlists-viewed-products",
    path: "/minilu/productlists/viewed-products",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/viewed-products/index.vue")
  },
  {
    name: "minilu-register",
    path: "/minilu/register",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/index.vue")
  },
  {
    name: "minilu-register-success",
    path: "/minilu/register/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/success.vue")
  },
  {
    name: "minilu-repairshop-checkout",
    path: "/minilu/repairshop/checkout",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/repairshop/checkout.vue")
  },
  {
    name: "minilu-sales-productId",
    path: "/minilu/sales/:productId()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/sales/[productId]/index.vue")
  },
  {
    name: "minilu-shop-slug",
    path: "/minilu/shop/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/[...slug]/index.vue")
  },
  {
    name: "minilu-shop-manufacturer-slug",
    path: "/minilu/shop/manufacturer/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/manufacturer/[...slug]/index.vue")
  },
  {
    name: "minilu-shop-product-slug-id",
    path: "/minilu/shop/product/:slug(.*)*/:id()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/product/[...slug]/[id].vue")
  },
  {
    name: "minilu-shop-search-slug",
    path: "/minilu/shop/search/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/search/[...slug]/index.vue")
  },
  {
    name: "vdv-_-banner-overview",
    path: "/vdv/_/banner/overview",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/_/banner/overview.vue")
  },
  {
    name: "vdv-slug",
    path: "/vdv/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/index.vue")
  },
  {
    name: "vdv-slug-thank-you",
    path: "/vdv/:slug(.*)*/thank-you",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/thank-you.vue")
  },
  {
    name: "vdv-404",
    path: "/vdv/404",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/404/index.vue")
  },
  {
    name: "vdv-account-changePassword",
    path: "/vdv/account/changePassword",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/index.vue")
  },
  {
    name: "vdv-account-changePassword-success",
    path: "/vdv/account/changePassword/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/success.vue")
  },
  {
    name: "vdv-account",
    path: "/vdv/account",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/index.vue")
  },
  {
    name: "vdv-account-orders",
    path: "/vdv/account/orders",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/orders/index.vue")
  },
  {
    name: "vdv-account-profile",
    path: "/vdv/account/profile",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/profile/index.vue")
  },
  {
    name: "vdv-account-resetPassword",
    path: "/vdv/account/resetPassword",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/index.vue")
  },
  {
    name: "vdv-account-resetPassword-success",
    path: "/vdv/account/resetPassword/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/success.vue")
  },
  {
    name: "vdv-account-returns-checkout-items",
    path: "/vdv/account/returns/checkout/items",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/items.vue")
  },
  {
    name: "vdv-account-returns-checkout-review",
    path: "/vdv/account/returns/checkout/review",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/review.vue")
  },
  {
    name: "vdv-account-returns-checkout-shipping",
    path: "/vdv/account/returns/checkout/shipping",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/shipping.vue")
  },
  {
    name: "vdv-account-returns-complaint-form",
    path: "/vdv/account/returns/complaint/form",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/complaint/form.vue")
  },
  {
    name: "vdv-account-returns",
    path: "/vdv/account/returns",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/index.vue")
  },
  {
    name: "vdv-checkout-address",
    path: "/vdv/checkout/address",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/address.vue")
  },
  {
    name: "vdv-checkout-basket",
    path: "/vdv/checkout/basket",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/basket.vue")
  },
  {
    name: "vdv-checkout-login",
    path: "/vdv/checkout/login",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/login.vue")
  },
  {
    name: "vdv-checkout-payment-shipping",
    path: "/vdv/checkout/payment-shipping",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/payment-shipping.vue")
  },
  {
    name: "vdv-checkout-review",
    path: "/vdv/checkout/review",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/review.vue")
  },
  {
    name: "vdv-debug-theme-test",
    path: "/vdv/debug/theme-test",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/theme-test.vue")
  },
  {
    name: "vdv-debug-tool",
    path: "/vdv/debug/tool",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/tool.vue")
  },
  {
    name: "vdv-login",
    path: "/vdv/login",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/index.vue")
  },
  {
    name: "vdv-login-success",
    path: "/vdv/login/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/success.vue")
  },
  {
    name: "vdv-preview-banner-slug",
    path: "/vdv/preview/banner/:slug()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/preview/banner/[slug].vue")
  },
  {
    name: "vdv-productlists-auto-delivery",
    path: "/vdv/productlists/auto-delivery",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/auto-delivery/index.vue")
  },
  {
    name: "vdv-productlists-favorites-slug-id",
    path: "/vdv/productlists/favorites/:slug(.*)*/:id()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/[...slug]/[id].vue")
  },
  {
    name: "vdv-productlists-favorites",
    path: "/vdv/productlists/favorites",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/index.vue")
  },
  {
    name: "vdv-productlists",
    path: "/vdv/productlists",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/index.vue")
  },
  {
    name: "vdv-productlists-order-alarm",
    path: "/vdv/productlists/order-alarm",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/order-alarm/index.vue")
  },
  {
    name: "vdv-productlists-ordered-products",
    path: "/vdv/productlists/ordered-products",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/ordered-products/index.vue")
  },
  {
    name: "vdv-productlists-viewed-products",
    path: "/vdv/productlists/viewed-products",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/viewed-products/index.vue")
  },
  {
    name: "vdv-register",
    path: "/vdv/register",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/index.vue")
  },
  {
    name: "vdv-register-success",
    path: "/vdv/register/success",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/success.vue")
  },
  {
    name: "vdv-repairshop-checkout",
    path: "/vdv/repairshop/checkout",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/repairshop/checkout.vue")
  },
  {
    name: "vdv-sales-productId",
    path: "/vdv/sales/:productId()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/sales/[productId]/index.vue")
  },
  {
    name: "vdv-shop-slug",
    path: "/vdv/shop/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/[...slug]/index.vue")
  },
  {
    name: "vdv-shop-manufacturer-slug",
    path: "/vdv/shop/manufacturer/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/manufacturer/[...slug]/index.vue")
  },
  {
    name: "vdv-shop-product-slug-id",
    path: "/vdv/shop/product/:slug(.*)*/:id()",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/product/[...slug]/[id].vue")
  },
  {
    name: "vdv-shop-search-slug",
    path: "/vdv/shop/search/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/search/[...slug]/index.vue")
  }
]