import payload_plugin_qLmFnukI99 from "/home/vsts/work/1/s/frontend/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/vsts/work/1/s/frontend/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/frontend/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/vsts/work/1/s/frontend/app/.nuxt/formkitPlugin.mjs";
import changeRoutes_HesVLh0f0m from "/home/vsts/work/1/s/frontend/app/src/plugins/changeRoutes.ts";
import debug_WetTHvTDfj from "/home/vsts/work/1/s/frontend/app/src/plugins/debug.ts";
import elasticAPM_client_zgOwQsB4Y0 from "/home/vsts/work/1/s/frontend/app/src/plugins/elasticAPM.client.ts";
import fontawesome_CH30yp3mE1 from "/home/vsts/work/1/s/frontend/app/src/plugins/fontawesome.ts";
import sentry_client_KAXFuL2wum from "/home/vsts/work/1/s/frontend/app/src/plugins/sentry.client.ts";
import theme_jPQ6APFowV from "/home/vsts/work/1/s/frontend/app/src/plugins/theme.ts";
export default [
  payload_plugin_qLmFnukI99,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formkitPlugin_pZqjah0RUG,
  changeRoutes_HesVLh0f0m,
  debug_WetTHvTDfj,
  elasticAPM_client_zgOwQsB4Y0,
  fontawesome_CH30yp3mE1,
  sentry_client_KAXFuL2wum,
  theme_jPQ6APFowV
]