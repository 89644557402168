/**
 * This file contains generic fetchers that can be used in any component.
 * These are not specific to any kind of API.
 */

import { hashCode } from '~~/src/helpers/hashCode';
import type { NitroFetchRequest, NitroFetchOptions } from 'nitropack';
import { useSessionStore } from '~/stores/useSessionStore';
import * as Sentry from '@sentry/vue';

/**
 * @summary
 * Wrapper around useFetch GET Methods.
 * This function is used to fetch data and store it in the nuxt data store (Client: __NUXT__).
 * This means that in the Client the data is already available and does not need to be fetched again.
 * Also adds the url-query to the request.
 *
 * @remark
 * With useFetch, data gets automatically written into NuxtDat (via the key), but it will not be automatically read.
 * Thats why we need this wrapper.
 */
export async function useCachedGet<T = any>(
  uri: NitroFetchRequest,
  options?: { blockId?: number | string },
): Promise<ReturnType<typeof useFetch<T>>> {
  const key =
    `__@uri_${uri}` + (options?.blockId ? `__@blockId_${options.blockId}` : '');
  const cachedData = useNuxtData<T>(key);
  let result: Awaited<ReturnType<typeof useFetch<T>>>;
  if (cachedData?.data?.value) {
    result = await Promise.resolve({
      data: cachedData.data as any,
      pending: ref(false),
      error: ref(null),
      status: ref('success'),
      refresh: (async () => {
        // eslint-disable-next-line no-console
        console.log('Cached NuxtData Refresh not implemented yet.');
      }) as ReturnType<typeof useFetch<T>>['refresh'],
      execute: (async () => {
        // eslint-disable-next-line no-console
        console.log('Cached NuxtData Execute not implemented yet.');
      }) as ReturnType<typeof useFetch<T>>['execute'],
      clear: (async () => {
        // eslint-disable-next-line no-console
        console.log('Cached NuxtData Clear not implemented yet.');
      }) as ReturnType<typeof useFetch<T>>['clear'],
    });
  } else {
    if (import.meta.client) await nextTick();
    const session = useSessionStore();
    result = await queryWithApmTracing(
      uri as string,
      async (apmHeaders) => {
        return await queryWithSentryTracing(uri, async (sentryHeaders) => {
          return useFetch<T>(uri, {
            query: useRoute().query,
            method: 'GET' as any,
            key,
            headers: {
              ...sentryHeaders,
              ...apmHeaders,
              'browser-session-id': session.browserSessionId,
            },
          });
        });
      },
      { labels: [{ fetcherType: 'cachedGet' }] },
    );
  }

  handleCachedLoaderError(result, uri);
  return result;
}

/**
 * @summary
 * Wrapper around useFetch POST Methods.
 * This function is used to fetch data and store it in the nuxt data store (Client: __NUXT__).
 * This means that in the Client the data is already available and does not need to be fetched again.
 * Also adds the url-query to the request.
 */
export async function useCachedPost<T = any>(
  uri: NitroFetchRequest,
  body: NitroFetchOptions<typeof uri, 'post'>['body'],
  options?: { blockId?: number | string },
) {
  const key =
    `__@uri_${uri}` +
    (options?.blockId ? `__@blockId_${options.blockId}` : '') +
    `__@hash_${hashCode(body)}`;

  const cachedData = useNuxtData<T>(key);
  if (cachedData?.data?.value) {
    return cachedData;
  }
  const session = useSessionStore();

  const result = await queryWithApmTracing(
    uri as string,
    async (apmHeaders) => {
      return await queryWithSentryTracing(uri, async (sentryHeaders) => {
        return useFetch<T>(uri, {
          query: useRoute().query,
          method: 'POST' as any,
          body,
          key,
          headers: {
            ...sentryHeaders,
            ...apmHeaders,
            'browser-session-id': session.browserSessionId,
          },
        });
      });
    },
    { labels: [{ fetcherType: 'cachedPost' }] },
  );

  handleCachedLoaderError(result, uri);
  return { data: result.data };
}

function handleCachedLoaderError<T = any>(
  result: Awaited<ReturnType<typeof useFetch<T>>>,
  uri: NitroFetchRequest,
) {
  if (result.error.value) {
    if (result.error.value.message === 'Shop is not enabled') {
      if (
        useRuntimeConfig().public.ENVIRONMENT_NAME !== 'Prod' &&
        import.meta.client
      ) {
        window.alert(
          `Shop route used when shop disabled - this is a bug. route: ${uri}`,
        );
      }
    }

    throw new Error(result.error.value.message);
  }
}

/**
 * @summary
 * Wrapper around POST Methods.
 * Also adds the url-query to the request.
 */
async function usePost<T>(uri: string, body: any) {
  const session = useSessionStore();
  return await queryWithApmTracing(
    uri,
    async (apmHeaders) => {
      return await queryWithSentryTracing(uri, async (sentryHeaders) => {
        const result = await $fetch<T>(uri, {
          query: useRoute().query,
          method: 'POST' as any,
          headers: {
            ...sentryHeaders,
            ...apmHeaders,
            'browser-session-id': session.browserSessionId,
          },
          body,
        });
        return result;
      });
    },
    { labels: [{ fetcherType: 'post' }] },
  );
}

export { usePost };
/**
 * @deprecated Use usePost instead.
 */
export { usePost as useSecurePost };

/**
 * @summary
 * Wrapper around $fetch Get Methods.
 * Also adds authentication headers to the request.
 * Also adds the url-query to the request.
 */
export async function useSessionGet<T = any>(
  uri: NitroFetchRequest,
  options?: NitroFetchOptions<typeof uri, 'get'>,
) {
  const session = useSessionStore();

  return useLogoutOn401(async () => {
    return await queryWithApmTracing(
      uri as string,
      async (apmHeaders) => {
        return await queryWithSentryTracing(uri, async (sentryHeaders) => {
          const result = await $fetch<T>(uri, {
            ...options,
            query: useRoute().query,
            method: 'GET' as any,
            headers: {
              'session-id': session.sessionId,
              'user-identifier': session.userIdentifier,
              'browser-session-id': session.browserSessionId,
              ...sentryHeaders,
              ...apmHeaders,
              ...(options?.headers ?? {}),
            },
          });
          return result;
        });
      },
      { labels: [{ fetcherType: 'sessionGet' }] },
    );
  }, uri);
}

/**
 * @summary
 * Wrapper around $fetch Delete Methods.
 * Also adds authentication headers to the request.
 */
async function useSessionDelete<T = any>(uri: NitroFetchRequest) {
  const session = useSessionStore();
  return useLogoutOn401(async () => {
    return await queryWithApmTracing(
      uri as string,
      async (apmHeaders) => {
        return await queryWithSentryTracing(uri, async (sentryHeaders) => {
          const result = await $fetch<T>(uri, {
            method: 'DELETE' as any,
            headers: {
              'session-id': session.sessionId,
              'user-identifier': session.userIdentifier,
              'browser-session-id': session.browserSessionId,
              ...sentryHeaders,
              ...apmHeaders,
            },
          });
          return result;
        });
      },
      { labels: [{ fetcherType: 'sessionDelete' }] },
    );
  }, uri);
}

export { useSessionDelete };
/**
 * @deprecated Use useSessionDelete instead.
 */
export { useSessionDelete as useSecureSessionDelete };

/**
 * @summary
 * Wrapper around Post Methods with the sesionId in the header.
 * Also adds authentication headers to the request.
 * Also adds the url-query to the request.
 */
async function useSessionPost<T = any>(
  uri: NitroFetchRequest,
  body: NitroFetchOptions<typeof uri, 'post'>['body'] = {},
  options: { noQuery?: boolean; headers?: Record<string, string> } = {},
) {
  const session = useSessionStore();
  return useLogoutOn401(async () => {
    return await queryWithApmTracing(
      uri as string,
      async (apmHeaders) => {
        return await queryWithSentryTracing(uri, async (sentryHeaders) => {
          const result = await $fetch<T>(uri, {
            query: !options?.noQuery ? useRoute().query : undefined,
            method: 'POST' as any,
            headers: {
              'session-id': session.sessionId,
              'user-identifier': session.userIdentifier,
              'browser-session-id': session.browserSessionId,
              ...sentryHeaders,
              ...apmHeaders,
              ...(options?.headers ?? {}),
            },
            body,
          });
          return result;
        });
      },
      { labels: [{ fetcherType: 'sessionPost' }] },
    );
  }, uri);
}

export { useSessionPost };
/**
 * @deprecated Use useSessionPost instead.
 */
export { useSessionPost as useSecureSessionPost };

export async function useLogoutOn401<T = any>(
  cb: () => Promise<T>,
  route: NitroFetchRequest = '',
): Promise<T> {
  try {
    return await cb();
  } catch (e: any) {
    const session = useSessionStore();
    if ([401, 403].includes(e.statusCode) && session.isLoggedIn) {
      useElasticApm()?.captureError({
        name: 'Autologout',
        message: 'useLogoutOn401',
        cause: JSON.stringify({ route, code: e.statusCode }, null, null),
        stack: e.stack,
      });
      nextTick(async () => {
        session.logout({ wasAutoLogout: true });
      });
    }
    throw e;
  }
}

async function queryWithApmTracing<T = any>(
  uri: string,
  cb: (apmHeaders: Record<string, string>) => Promise<T>,
  options?: {
    method?: string;
    name?: string;
    labels?: Array<Record<string, string>>;
  },
) {
  const apm = useElasticApm();
  if (!apm) return await cb({});

  const apmTransaction = apm.startTransaction(
    options?.method ? `${options?.method} ${uri}` : uri,
    options?.name ?? 'api-call-client',
  ) as ReturnType<(typeof apm)['startTransaction']> & {
    traceId: string;
    id: string;
  };

  for (const label of options?.labels ?? []) {
    apmTransaction.addLabels(label);
  }
  apmTransaction.addLabels({ pageUrl: window?.location?.href });
  apmTransaction.addLabels({
    browserSessionId: useSessionStore().browserSessionId,
  });

  let result: T = null;
  try {
    result = await cb(
      getApmHeaders({ id: apmTransaction.id, traceId: apmTransaction.traceId }),
    );
  } finally {
    apmTransaction?.end();
  }
  return result;
}

function getApmHeaders(data: { id: string; traceId: string }) {
  if (!data) return {};
  return {
    // https://www.w3.org/TR/trace-context/#examples-of-http-traceparent-headers
    'elastic-apm-traceparent': `00-${data.traceId}-${data.id}-01`,
    traceparent: `00-${data.traceId}-${data.id}-01`,
  };
}

async function queryWithSentryTracing<T = any>(
  uri: NitroFetchRequest,
  cb: (sentryHeaders: Record<string, string>) => Promise<T>,
) {
  // Comment back an in if needed, so long just leave it like this to keep sentry tracing disabled
  const sentryEnabled = false; //useClientSentryEnabled();
  if (!import.meta.client || !sentryEnabled) {
    return await cb({});
  }
  return await Sentry.startSpan(
    { name: uri as string, op: 'client-api-call' },
    async (span) => {
      const sentryHeaders = getSentryPropagationHeaders({
        spanId: span.spanContext().spanId,
        traceId: span.spanContext().traceId,
      });
      return await cb(sentryHeaders);
    },
  );
}

export function useClientSentryEnabled() {
  const sentryEnabled = useRuntimeConfig().public.SENTRY_CLIENT_ENABLE;
  return typeof sentryEnabled === 'number'
    ? sentryEnabled === 1
    : sentryEnabled === '1';
}

function getSentryPropagationHeaders(span?: {
  spanId: string;
  traceId: string;
}) {
  const headers = {
    'sentry-trace': '',
    baggage: '',
  };

  const { spanId, traceId } =
    Sentry.getCurrentScope()?.getPropagationContext() ?? {
      spanId: null,
      traceId: null,
    };
  const replayId = Sentry.getReplay()?.getReplayId();
  const sentryEnv = Sentry.getClient()?.getOptions()?.environment;
  const pubKey = Sentry.getClient()?.getDsn()?.publicKey;

  headers['baggage'] =
    `sentry-environment=${sentryEnv},sentry-public_key=${pubKey},sentry-trace_id=${
      span?.traceId ?? traceId
    },sentry-replay_id=${replayId}`;
  headers['sentry-trace'] = `${span?.traceId ?? traceId}-${
    span?.spanId ?? spanId
  }`;
  return headers;
}
