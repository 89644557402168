/* eslint-disable no-console */
import { defineNuxtPlugin } from '#app';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
export default defineNuxtPlugin((nuxtApp) => {
  const serverUrl = useRuntimeConfig().public.ELASTICAPM_CLIENT_SERVER_URL;
  if (!serverUrl) return;
  const name = 'frontend-client';

  const { vueApp } = nuxtApp;
  vueApp.use(ApmVuePlugin, {
    config: {
      serviceName: name,
      serverUrl,
      environment: useRuntimeConfig().public.ENVIRONMENT_NAME ?? 'local',
      transactionSampleRate: parseFloat(
        useRuntimeConfig().public.ELASTICAPM_CLIENT_SAMPLE_RATE ?? '0.0',
      ),
      disableInstrumentations: ['fetch', 'xmlhttprequest'], // Firefox does not support fetch instrumentation properly due to CSP
      propagateTracestate: true,
      pageLoadSampled: true,
      breakdownMetrics: true,
      distributedTracingOrigins: [
        window.location.origin,
        'localhost',
        'https://shop-dev.minilu.de',
        'https://shop-staging.minilu.de',
        '/api',
        /.*/, // fallback for all other origins
      ],
    },
    router: nuxtApp.router,
  });
  if (import.meta.client) {
    console.info('✨ Elastic RUM initialized | Service: ' + name);
  }
});
